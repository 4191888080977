<section class="user-menu">
  <a
    (click)="toggleMenu()"
    class="user-menu__icon">
    <span
      *ngIf="user && !user.profileImageUrl"
      class="user-menu__icon__default">{{userShortName}}</span>
    <img-lazy-loading
      class="user-menu__icon__avatar"
      *ngIf="user && user.profileImageUrl"
      [mainThumb]="user.profileImageUrl"
      [thumbs]="user.profileImgThbs"></img-lazy-loading>
  </a>
  <ul
    *ngIf="menuVisible && user"
    class="user-menu__links">
    <li class="user-menu__links__list mobile--button">
      <a
        class="user-menu__links__list mobile--button__button--close"
        (click)="hideMenu()">
        Close 
        <svg height="512px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"/></svg>
      </a>
    </li>
    <li class="user-menu__links__list">
      <a class="user-menu__links__list__link" [routerLink]="['/user', user.id, 'my-account']">
        <svg class="user-menu__links__list__link__icon" viewBox="0 0 81.99 98.27" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 2" id="Layer_2"><g data-name="Layer 1" id="Layer_1-2"><path d="M40.37,47.34A22.9,22.9,0,0,0,57.11,40.4,22.9,22.9,0,0,0,64,23.67,22.91,22.91,0,0,0,57.11,6.93,22.91,22.91,0,0,0,40.37,0,22.9,22.9,0,0,0,23.64,6.93,22.9,22.9,0,0,0,16.7,23.67,22.9,22.9,0,0,0,23.64,40.4,22.91,22.91,0,0,0,40.37,47.34Z"/><path d="M81.79,75.57a58.46,58.46,0,0,0-.8-6.21,48.92,48.92,0,0,0-1.53-6.24,30.84,30.84,0,0,0-2.57-5.82,22,22,0,0,0-3.87-5,17.06,17.06,0,0,0-5.56-3.49,19.21,19.21,0,0,0-7.1-1.28c-1,0-2,.41-3.85,1.63l-4,2.58A23,23,0,0,1,47.33,54a20.14,20.14,0,0,1-12.68,0,22.91,22.91,0,0,1-5.18-2.28l-4-2.59c-1.87-1.22-2.84-1.63-3.85-1.63a19.19,19.19,0,0,0-7.1,1.29A17,17,0,0,0,9,52.25a22,22,0,0,0-3.87,5,30.9,30.9,0,0,0-2.57,5.82A49,49,0,0,0,1,69.36a58.26,58.26,0,0,0-.8,6.21C.07,77.45,0,79.4,0,81.37c0,5.13,1.63,9.28,4.85,12.35s7.38,4.56,12.49,4.56H64.65c5.11,0,9.31-1.53,12.49-4.56S82,86.5,82,81.37C82,79.39,81.92,77.44,81.79,75.57Z"/></g></g></svg>
        <span class="user-menu__links__list__link__title">My account</span>
      </a>
    </li>
    <li class="user-menu__links__list">
      <a class="user-menu__links__list__link" [routerLink]="['/user', 'notifications']">
        <svg class="user-menu__links__list__link__icon" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="M440.08,341.31c-1.66-2-3.29-4-4.89-5.93-22-26.61-35.31-42.67-35.31-118,0-39-9.33-71-27.72-95-13.56-17.73-31.89-31.18-56.05-41.12a3,3,0,0,1-.82-.67C306.6,51.49,282.82,32,256,32s-50.59,19.49-59.28,48.56a3.13,3.13,0,0,1-.81.65c-56.38,23.21-83.78,67.74-83.78,136.14,0,75.36-13.29,91.42-35.31,118-1.6,1.93-3.23,3.89-4.89,5.93a35.16,35.16,0,0,0-4.65,37.62c6.17,13,19.32,21.07,34.33,21.07H410.5c14.94,0,28-8.06,34.19-21A35.17,35.17,0,0,0,440.08,341.31Z"/><path d="M256,480a80.06,80.06,0,0,0,70.44-42.13,4,4,0,0,0-3.54-5.87H189.12a4,4,0,0,0-3.55,5.87A80.06,80.06,0,0,0,256,480Z"/></svg>
        <span class="user-menu__links__list__link__title">Notifications</span>
      </a>
    </li>
    <li class="user-menu__links__list">
      <a class="user-menu__links__list__link" [routerLink]="['/user', user.id, 'earnings']">
        <svg class="user-menu__links__list__link__icon" id="Layer_2" style="enable-background:new 0 0 32 32;" version="1.1" viewBox="0 0 32 32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M26,3.35999H6c-3.03003,0-5.5,2.46002-5.5,5.5v14.28003c0,3.03998,2.46997,5.5,5.5,5.5h20c3.03003,0,5.5-2.46002,5.5-5.5   V8.85999C31.5,5.82001,29.03003,3.35999,26,3.35999z M28.5,9.07996h-7.08002C17.59998,9.07996,14.5,12.19,14.5,16   s3.09998,6.91998,6.91998,6.91998H28.5v0.22003c0,1.37994-1.12,2.5-2.5,2.5H6c-1.38,0-2.5-1.12006-2.5-2.5V8.85999   c0-1.38,1.12-2.5,2.5-2.5h20c1.38,0,2.5,1.12,2.5,2.5V9.07996z M24.82001,16c0,1.57996-1.28998,2.87-2.87,2.87   s-2.87-1.29004-2.87-2.87c0-1.58002,1.28998-2.87,2.87-2.87S24.82001,14.41998,24.82001,16z"/></g></svg>
        <span class="user-menu__links__list__link__title">My Earnings</span>
      </a>
    </li>
    <li class="user-menu__links__list">
      <a class="user-menu__links__list__link" [routerLink]="['/streams']">
        <svg class="user-menu__links__list__link__icon icon--streams" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 5.5C3 4.11929 4.11929 3 5.5 3H10.5C11.8807 3 13 4.11929 13 5.5C13 5.77614 12.7761 6 12.5 6C12.2239 6 12 5.77614 12 5.5C12 4.67157 11.3284 4 10.5 4H5.5C4.67157 4 4 4.67157 4 5.5V10.5C4 11.3284 4.67157 12 5.5 12H10.0137C10.1381 10.875 11.0919 10 12.25 10C13.4926 10 14.5 11.0074 14.5 12.25C14.5 13.4926 13.4926 14.5 12.25 14.5C11.2703 14.5 10.4369 13.8739 10.128 13H5.5C4.11929 13 3 11.8807 3 10.5V5.5ZM7.5 14C7.22386 14 7 14.2239 7 14.5C7 15.8807 8.11929 17 9.5 17H14.5C15.8807 17 17 15.8807 17 14.5V9.5C17 8.11929 15.8807 7 14.5 7H9.87197C9.56309 6.12611 8.72966 5.5 7.75 5.5C6.50736 5.5 5.5 6.50736 5.5 7.75C5.5 8.99264 6.50736 10 7.75 10C8.90814 10 9.86191 9.12499 9.98627 8H14.5C15.3284 8 16 8.67157 16 9.5V14.5C16 15.3284 15.3284 16 14.5 16H9.5C8.67157 16 8 15.3284 8 14.5C8 14.2239 7.77614 14 7.5 14Z" fill="#212121"/></svg>
        <span class="user-menu__links__list__link__title">My Streams</span>
      </a>
    </li>
    <li class="user-menu__links__list">
      <a class="user-menu__links__list__link" [routerLink]="['/user', 'my-notes']">
        <svg class="user-menu__links__list__link__icon" enable-background="new 0 0 512 512" id="Layer_1" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M434.5,72.3V412c0,33.6-27.5,61.1-61,61.1H126.7c-5.6,0-11-1-16.2-2.5c7.8,23.7,30,41.1,56.2,41.1h249.9   c32.7,0,59.5-26.8,59.5-59.5V128.8C476.1,102.3,458.5,80,434.5,72.3z" fill="#000"/><path d="M345.2,0H95.4C62.7,0,35.9,26.8,35.9,59.5V383c0,32.7,26.8,59.5,59.5,59.5h249.9   c32.7,0,59.5-26.8,59.5-59.5V59.5C404.7,26.8,378,0,345.2,0z M364.2,349.8c0,25.7-21.1,46.8-46.8,46.8H120.9   c-25.7,0-46.8-21.1-46.8-46.8V95.4c0-25.7,21.1-46.8,46.8-46.8h196.6c25.7,0,46.8,21.1,46.8,46.8V349.8z" fill="#000"/><g><path d="M319.8,117.5H190.5c-5.4,0-9.8-4.4-9.8-9.8v-0.7c0-5.4,4.4-9.8,9.8-9.8h129.3c5.4,0,9.8,4.4,9.8,9.8v0.7    C329.7,113.1,325.2,117.5,319.8,117.5z" fill="#000"/><path d="M319.8,175.2H120.4c-5.4,0-9.8-4.4-9.8-9.8v-0.7c0-5.4,4.4-9.8,9.8-9.8h199.5c5.4,0,9.8,4.4,9.8,9.8v0.7    C329.7,170.7,325.2,175.2,319.8,175.2z" fill="#000"/><path d="M319.8,232.8H120.4c-5.4,0-9.8-4.4-9.8-9.8v-0.7c0-5.4,4.4-9.8,9.8-9.8h199.5c5.4,0,9.8,4.4,9.8,9.8v0.7    C329.7,228.4,325.2,232.8,319.8,232.8z" fill="#000"/><path d="M319.8,290.5H120.4c-5.4,0-9.8-4.4-9.8-9.8v-0.7c0-5.4,4.4-9.8,9.8-9.8h199.5c5.4,0,9.8,4.4,9.8,9.8v0.7    C329.7,286.1,325.2,290.5,319.8,290.5z" fill="#000"/><path d="M319.8,348.2H120.4c-5.4,0-9.8-4.4-9.8-9.8v-0.7c0-5.4,4.4-9.8,9.8-9.8h199.5c5.4,0,9.8,4.4,9.8,9.8v0.7    C329.7,343.7,325.2,348.2,319.8,348.2z" fill="#000"/></g></g></svg>
        <span class="user-menu__links__list__link__title">My Notes</span>
      </a>
    </li>
    <li class="user-menu__links__list">
      <a class="user-menu__links__list__link" [routerLink]="['/user', 'my-subscriptions']">
        <svg class="user-menu__links__list__link__icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><path d="M0 0h24v24H0z" fill="none"/><path d="M18 7h3a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h15v4zM4 9v10h16V9H4zm0-4v2h12V5H4zm11 8h3v2h-3v-2z"/></g></svg>
        <span class="user-menu__links__list__link__title">My Subscriptions</span>
      </a>
    </li>
    <li class="user-menu__links__list">
      <a class="user-menu__links__list__link" [routerLink]="['/user', user.id, 'saved-notes']">
        <svg class="user-menu__links__list__link__icon" enable-background="new 0 0 48 48" height="48px" id="Layer_1" version="1.1" viewBox="0 0 48 48" width="48px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M27.26,17.394h-6.486c-0.667,0-1.208,0.531-1.208,1.187v5.014v0.966v4.989   c0.003,0.078,0.076,0.322,0.92-0.022h0l3.028-1.234c0.01-0.004,0.556-0.191,1.026,0.001l3.486,1.427   c0.201,0.067,0.443,0.104,0.443-0.109V24.56v-0.966V18.58C28.469,17.925,27.928,17.394,27.26,17.394z" fill="#231F20"/><path d="M24,0C10.745,0,0,10.745,0,24s10.745,24,24,24s24-10.745,24-24S37.255,0,24,0z M31.5,24.026v1.628v8.52   c0,0.388-0.568,0.232-0.912,0.104l-5.884-2.408c0,0,0,0,0,0c-0.663-0.271-1.332-0.018-1.371-0.003l-5.566,2.269h0   c-1.156,0.472-1.26,0.146-1.265,0.035v-8.517v-1.628V15.58c0-1.104,0.912-2,2.036-2h10.926c1.125,0,2.037,0.896,2.037,2V24.026z" fill="#231F20"/></g></svg>
        <span class="user-menu__links__list__link__title">Saved Notes</span>
      </a>
    </li>
    <li class="user-menu__links__list">
      <a class="user-menu__links__list__link" [routerLink]="['/team']">
        <svg class="user-menu__links__list__link__icon" fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M14.754 10C15.7205 10 16.504 10.7835 16.504 11.75V16.499C16.504 18.9848 14.4888 21 12.003 21C9.51712 21 7.50193 18.9848 7.50193 16.499V11.75C7.50193 10.7835 8.28543 10 9.25193 10H14.754ZM7.13128 9.99906C6.78183 10.4218 6.55636 10.9508 6.51057 11.5304L6.50193 11.75V16.499C6.50193 17.3456 6.69319 18.1476 7.03487 18.864C6.70577 18.953 6.35899 19 6.00124 19C3.79142 19 2 17.2086 2 14.9988V11.75C2 10.8318 2.70711 10.0788 3.60647 10.0058L3.75 10L7.13128 9.99906ZM16.8747 9.99906L20.25 10C21.2165 10 22 10.7835 22 11.75V15C22 17.2091 20.2091 19 18 19C17.6436 19 17.298 18.9534 16.9691 18.8659C17.2697 18.238 17.4538 17.5452 17.4951 16.8144L17.504 16.499V11.75C17.504 11.0847 17.2678 10.4747 16.8747 9.99906ZM12 3C13.6569 3 15 4.34315 15 6C15 7.65685 13.6569 9 12 9C10.3431 9 9 7.65685 9 6C9 4.34315 10.3431 3 12 3ZM18.5 4C19.8807 4 21 5.11929 21 6.5C21 7.88071 19.8807 9 18.5 9C17.1193 9 16 7.88071 16 6.5C16 5.11929 17.1193 4 18.5 4ZM5.5 4C6.88071 4 8 5.11929 8 6.5C8 7.88071 6.88071 9 5.5 9C4.11929 9 3 7.88071 3 6.5C3 5.11929 4.11929 4 5.5 4Z" fill="#212121"/></svg>
        <span class="user-menu__links__list__link__title">Team Account</span>
      </a>
    </li>
    <li class="user-menu__links__list">
      <a class="user-menu__links__list__link" [routerLink]="['/user', 'followed-streams']">
        <svg class="user-menu__links__list__link__icon" enable-background="new 0 0 128 128" height="128px" id="Layer_1" version="1.1" viewBox="0 0 128 128" width="128px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M127,44.205c0-18.395-14.913-33.308-33.307-33.308c-12.979,0-24.199,7.441-29.692,18.276  c-5.497-10.835-16.714-18.274-29.694-18.274C15.912,10.898,1,25.81,1,44.205C1,79,56.879,117.104,64.001,117.104  C71.124,117.104,127,79.167,127,44.205z" fill="#232323"/></svg>
        <span class="user-menu__links__list__link__title">Followed Streams</span>
      </a>
    </li>
    <li class="user-menu__links__list">
      <a class="user-menu__links__list__link" [routerLink]="['/user', 'trash']">
        <svg class="user-menu__links__list__link__icon" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M413.7,133.4c-2.4-9-4-14-4-14c-2.6-9.3-9.2-9.3-19-10.9l-53.1-6.7c-6.6-1.1-6.6-1.1-9.2-6.8c-8.7-19.6-11.4-31-20.9-31   h-103c-9.5,0-12.1,11.4-20.8,31.1c-2.6,5.6-2.6,5.6-9.2,6.8l-53.2,6.7c-9.7,1.6-16.7,2.5-19.3,11.8c0,0-1.2,4.1-3.7,13   c-3.2,11.9-4.5,10.6,6.5,10.6h302.4C418.2,144.1,417,145.3,413.7,133.4z"/><path d="M379.4,176H132.6c-16.6,0-17.4,2.2-16.4,14.7l18.7,242.6c1.6,12.3,2.8,14.8,17.5,14.8h207.2c14.7,0,15.9-2.5,17.5-14.8   l18.7-242.6C396.8,178.1,396,176,379.4,176z"/></g></svg>
        <span class="user-menu__links__list__link__title">Trash</span>
      </a>
    </li>
    <li class="user-menu__links__list">
      <a class="user-menu__links__list__link" (click)="openShareModal()">
        <svg class="user-menu__links__list__link__icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M21,18.5a3.5,3.5,0,0,1-7,0,3.26,3.26,0,0,1,.11-.83L8.87,15.06A3.46,3.46,0,0,1,6.5,16a3.5,3.5,0,0,1,0-7,3.46,3.46,0,0,1,2.37.94l5.24-2.61A3.26,3.26,0,0,1,14,6.5,3.5,3.5,0,1,1,17.5,10a3.46,3.46,0,0,1-2.37-.94L9.89,11.67a3.19,3.19,0,0,1,0,1.66l5.24,2.61A3.46,3.46,0,0,1,17.5,15,3.5,3.5,0,0,1,21,18.5Z" fill="#464646"/></svg>
        <span class="user-menu__links__list__link__title">Share</span>
      </a>
    </li>
    <li class="user-menu__links__list">
      <a class="user-menu__links__list__link" (click)="emitLogOutEvent()">
        <svg class="user-menu__links__list__link__icon" fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M3 13H15V11H3V13Z" fill="black" fill-rule="evenodd"/><path clip-rule="evenodd" d="M5.79282 7.79291L2.29282 11.2929C1.90229 11.6834 1.90229 12.3166 2.29282 12.7071L5.79282 16.2071L7.20703 14.7929L4.41414 12L7.20703 9.20712L5.79282 7.79291Z" fill="black" fill-rule="evenodd"/><path clip-rule="evenodd" d="M8 4C8 3.44772 8.44772 3 9 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H9C8.44772 21 8 20.5523 8 20V17H10V19H20V5H10V7H8V4Z" fill="black" fill-rule="evenodd"/></svg>
        <span class="user-menu__links__list__link__title">Log Out</span>
      </a>
    </li>
  </ul>
  <ul
    *ngIf="menuVisible && !user"
    class="user-menu__links">
    <li class="user-menu__links__list mobile--button">
      <a
        class="user-menu__links__list mobile--button__button--close"
        (click)="hideMenu()">
        Close 
        <svg height="512px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"/></svg>
      </a>
    </li>
    <li class="user-menu__links__list">
      <a class="user-menu__links__list__link" [routerLink]="['/about']">
        <span class="user-menu__links__list__link__title">About Us</span>
      </a>
    </li>
    <li class="user-menu__links__list">
      <a class="user-menu__links__list__link" (click)="openContactFormModal()">
        <span class="user-menu__links__list__link__title">Contact</span>
      </a>
    </li>
    <li class="user-menu__links__list">
      <a class="user-menu__links__list__link" (click)="openShareModal()">
        <span class="user-menu__links__list__link__title">Share</span>
      </a>
    </li>
    <li class="user-menu__links__list">
      <a class="user-menu__links__list__link" transact-auth [partnersModal]="notdPartnersModal">
        <span class="user-menu__links__list__link__title">Sign-up or Login</span>
      </a>
      <notd-partners-modal
          #notdPartnersModal
          [splitButtons]="true"
          [buttonLabel]="'Sign-up or Login'"
          [customReturnUrl]="{signup: {enable: true, url: 'info/signup-thankyou'}}"></notd-partners-modal>
    </li>
  </ul>
</section>

<share-button
  [contentElRef]="notdScreenElement"
  [shareContent]="{title: 'Check out the content on Notd.io!', message: 'Don`t wait! Just read and create your content on Notd.io for free!' }"></share-button>

<section
  class="share-img-content"
  #shareImgContent>
  <img class="share-img-content__logo" src="../../assets/logo-black.png" alt="Notd.io">
  <h1>
    Finally! A Fully Customizable, Ad-Free News and Information Network
  </h1>
  <h2>
    Notd is a revolutionary publishing network: a go-to source for unbiased news, podcasts, photography, and more.
  </h2>
</section>
