import { Routes } from '@angular/router';

import { UserResolve } from './shared/resolvers/user-resolve.service';
import { NotePurchaseStatusResolve } from './shared/resolvers/note-purchase-status-resolve.service';
import { StreamDetailsResolve, StreamResolve } from './shared/resolvers/stream-resolve.service';
import { NoteResolve } from './shared/resolvers/note-resolve.service';
import { LoginRedirectGuard } from './shared/route-guards/login-redirect.guard';
import { UserStreamsRedirectGuard } from './shared/route-guards/user-streams-redirect.guard';
import { NoteDraftGuard } from './shared/route-guards/note-draft.guard';
import { StreamIdGuard } from './shared/route-guards/stream-id.guard';
import { NoteGuideGuard } from './shared/route-guards/note-guide.guard';
import { GuardQueue } from './shared/route-guards/guard-queue.quard';
import { AuthGuard } from './shared/route-guards/auth.guard';
import { StreamOwnerGuard } from './shared/route-guards/stream-owner.guard';
import { NoteOwnerGuard } from './shared/route-guards/note-owner.guard';

/*
 Route Configuration, Please Set new Route by component on routes directory.
 */
export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
    pathMatch: 'full',
    resolve: {
      user: UserResolve
    }
  },
  {
    path: 'm',
    resolve: {
      user: UserResolve
    },
    children: [
      {
        path: 'latest',
        loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
        data: {
          mode: 'latest'
        }
      },
      {
        path: 'popular',
        loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
        data: {
          mode: 'popular'
        }
      }
    ]
  },
  {
    path: 'why-publish',
    loadChildren: () => import('./why-publish/why-publish.module').then(m => m.WhyPublishModule),
    resolve: {
      user: UserResolve
    }
  },
  {
    path: 'why-publish:query',
    loadChildren: () => import('./why-publish/why-publish.module').then(m => m.WhyPublishModule),
    resolve: {
      user: UserResolve
    }
  },
  {
    path: 'how-notd-works',
    loadChildren: () => import('./how-notd-works/how-notd-works.module').then(m => m.HowNotdWorksModule),
    resolve: {
      user: UserResolve
    }
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    resolve: {
      user: UserResolve,
      streams: StreamResolve
    }
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about-us.module').then(m => m.AboutUsModule),
    resolve: {
      user: UserResolve
    }
  },
  {
    path: 'sitemap',
    loadChildren: () => import('./sitemap/sitemap.module').then(m => m.SiteMapModule),
    resolve: {
      user: UserResolve
    }
  },
  // {
  //   path: 'promotion',
  //   loadChildren: './promotion/promotion.module#PromotionModule',
  //   resolve: {
  //     user: UserResolve
  //   }
  // },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule),
    resolve: {
      user: UserResolve
    }
  },
  {
    path: 'ci-test',
    loadChildren: () => import('./ci-test/ci-test.module').then(m => m.CiTestModule),
    resolve: {
      user: UserResolve
    }
  },
  {
    path: 'how-to-use-podcast',
    loadChildren: () => import('./how-to-use-podcast/how-to-use-podcast.module').then(m => m.HowToUsePodcastModule),
    resolve: {
      user: UserResolve
    }
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then(m => m.TermsModule),
    resolve: {
        user: UserResolve
    }
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
    resolve: {
      user: UserResolve
    }
  },
  {
    path: 'notes/publish-thank-you/:id',
    loadChildren: () => import('./publish-thank-you/publish-thank-you.module').then(m => m.PublishThankYouModule),
    resolve: {
      note: NoteResolve,
      user: UserResolve
    },
    canActivate: [NoteOwnerGuard]
  },
  {
    path: 'notes/:id',
    resolve: {
      note: NoteResolve,
      user: UserResolve,
      noteStatus: NotePurchaseStatusResolve
    },
    canActivate: [NoteDraftGuard],
    children: [
      {
        path: 'publish-thank-you',
        loadChildren: () => import('./publish-thank-you/publish-thank-you.module').then(m => m.PublishThankYouModule),
        canActivate: [NoteOwnerGuard]
      },
      {
        path: ':name',
        loadChildren: () => import('./note-details/note-details.module').then(m => m.NoteDetailsModule),
      }
    ]
  },
  {
    path: 'n/:custom-url',
    loadChildren: () => import('./note-details/note-details.module').then(m => m.NoteDetailsModule),
    resolve: {
      note: NoteResolve,
      user: UserResolve,
      noteStatus: NotePurchaseStatusResolve
    },
    canActivate: [NoteDraftGuard]
  },
  {
    path: 'thread/:id',
    loadChildren: () => import('./thread/thread.module').then(m => m.ThreadModule),
    resolve: {
      user: UserResolve,
      note: NoteResolve
    }
  },
  {
    path: 'note',
    loadChildren: () => import('./shared/note-detailed/creator/note-creator.module').then(m => m.NoteCreatorModule),
    resolve: {
      user: UserResolve
    },
    canActivate: [UserStreamsRedirectGuard]
  },
  {
    path: 'note/:id',
    loadChildren: () => import('./shared/note-detailed/creator/note-creator.module').then(m => m.NoteCreatorModule),
    resolve: {
        user: UserResolve
    },
    canActivate: [LoginRedirectGuard]
  },
  {
    path: 'new-note-guide',
    loadChildren: () => import('./create-note-guide/create-note-guide.module').then(m => m.CreateNoteGuideModule),
    canActivate: [GuardQueue],
    data: {
      guards: [LoginRedirectGuard, AuthGuard, NoteGuideGuard]
    }
  },
  {
    path: 'stream/:streamId/member/invite/accept/:invitationToken',
    loadChildren: () => import('./streams/stream-community-invitation/stream-community-invitation.module')
      .then(m => m.StreamCommunityInvitationModule),
    canActivate: [LoginRedirectGuard],
    resolve: {
      user: UserResolve
    }
  },
  {
    path: 'streams/:id/view/:myStreamName',
    loadChildren: () => import('./streams/stream-details/stream-details.module').then(m => m.StreamDetailsModule),
    resolve: {
      user: UserResolve,
      requestedStream: StreamDetailsResolve
    },
    canActivate: [StreamIdGuard]
  },
  {
    path: 'streams/:id/edit',
    loadChildren: () => import('./streams/stream-edit-view/stream-edit-view.module').then(m => m.StreamEditViewModule),
    resolve: {
      user: UserResolve,
      stream: StreamDetailsResolve
    },
    data: {
      guards: [StreamIdGuard, StreamOwnerGuard]
    }
  },
  {
    path: 's/:custom-url/edit',
    loadChildren: () => import('./streams/stream-edit-view/stream-edit-view.module').then(m => m.StreamEditViewModule),
    resolve: {
      user: UserResolve,
      stream: StreamDetailsResolve
    },
    canActivate: [StreamIdGuard, StreamOwnerGuard]
  },
  {
    path: 'streams/create',
    loadChildren: () => import('./streams/stream-create/stream-create.module').then(m => m.StreamCreateModule),
    canActivate: [GuardQueue],
    resolve: {
      user: UserResolve,
    },
    data: {
      guards: [LoginRedirectGuard, AuthGuard]
    }
  },
  {
    path: 'streams/:id/owner',
    canActivate: [StreamOwnerGuard],
    resolve: {
      user: UserResolve,
      requestedStream: StreamDetailsResolve
    },
    children: [
      {
        path: 'profile',
        loadChildren: () => import('./streams/stream-details/stream-details.module').then(m => m.StreamDetailsModule),
        resolve: {
          user: UserResolve,
          requestedStream: StreamDetailsResolve
        },
        data: {
          mode: 'profile'
        }
      },
      {
        path: 'subscription/settings',
        loadChildren: () => import('./streams/stream-details/stream-details.module').then(m => m.StreamDetailsModule),
        resolve: {
          user: UserResolve,
          requestedStream: StreamDetailsResolve
        },
        data: {
          mode: 'subscription/settings'
        }
      },
      {
        path: 'analytics/page-views',
        loadChildren: () => import('./streams/stream-details/stream-details.module').then(m => m.StreamDetailsModule),
        resolve: {
          user: UserResolve,
          requestedStream: StreamDetailsResolve
        },
        data: {
          mode: 'analytics/page-views'
        }
      },
      {
        path: '**',
        redirectTo: ''
      }
    ]
  },
  {
    path: 's/:custom-url',
    loadChildren: () => import('./streams/stream-details/stream-details.module').then(m => m.StreamDetailsModule),
    resolve: {
      user: UserResolve,
      requestedStream: StreamDetailsResolve
    },
    canActivate: [StreamIdGuard]
  },
  {
    path: 'streams/:id',
    loadChildren: () => import('./streams/stream-details/stream-details.module').then(m => m.StreamDetailsModule),
    resolve: {
      user: UserResolve,
      requestedStream: StreamDetailsResolve,
    },
    canActivate: [StreamIdGuard]
  },
  {
    path: 'streams',
    loadChildren: () => import('./streams/stream-base/stream-base.module').then(m => m.StreamBaseModule),
    canActivate: [LoginRedirectGuard],
    resolve: {
      ownedStreams: StreamResolve
    }
  },
  {
    path: 'popular',
    resolve: {
      user: UserResolve
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./popular/popular.module').then(m => m.PupularModule)
      },
      {
        path: ':input',
        loadChildren: () => import('./popular/popular.module').then(m => m.PupularModule)
      }
    ]
  },
  {
    path: 'team/:teamId/member/invite/accept/:invitationToken',
    loadChildren: () => import('./team/team-invitation/team-invitation.module').then(m => m.TeamInvitationModule),
    canActivate: [LoginRedirectGuard],
    resolve: {
      user: UserResolve
    }
  },
  {
    path: 'team',
    loadChildren: () => import('./team/team.module').then(m => m.TeamModule),
    canActivate: [LoginRedirectGuard],
    resolve: {
      user: UserResolve
    }
  },
  {
    path: 'search/:query',
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
    resolve: {
      user: UserResolve
    }
  },
  {
    path: 'search/query/:type/:query',
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
    resolve: {
      user: UserResolve
    }
  },
  {
    path: 'search/:form/:category',
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
    resolve: {
      user: UserResolve
    }
  },
  {
    path: 'search/:form/:category/:type',
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
    resolve: {
        user: UserResolve
    }
  },
  {
    path: 'search/:form/:category/:type/:query',
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
    pathMatch: 'full',
    resolve: {
      user: UserResolve
    }
  },
  {
    path: 'info',
    children: [
      {
        path: 'signup-thankyou',
        loadChildren: () => import('./info/signup-thankyou/signup.module').then(m => m.SignupThankYouModule),
        resolve: {
          user: UserResolve
        }
      }
    ]
  },
  {
    path: '404',
    loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule),
    resolve: {
      user: UserResolve
    },
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '',
  }
];
