import { Component, Inject, PLATFORM_ID, Renderer2, ViewEncapsulation} from '@angular/core';
import { MetaService } from './shared/services/meta_service';
import { UserService } from './shared/services/user_service';
import { A2hsService } from './shared/services/a2hs.service';
import { GtmService } from './shared/services/gtm_service';
import { AnalyticsService } from './shared/services/analytics_service';
import {isPlatformBrowser} from '@angular/common';

import { filter } from 'rxjs';

import {ConfirmationModalComponent} from './shared/confirmation-modal/confirmation-modal.component';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { Util } from './shared/util/util';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  userLoggedStatus: any;
  currentLoginState: boolean;
  public isDesktop = false;

  constructor(private metaService: MetaService,
              private userService: UserService,
              private modalService: NgbModal,
              public a2hsService: A2hsService,
              private utilities: Util,
              private renderer: Renderer2,
              private router: Router,
              private route: ActivatedRoute,
              private gtmService: GtmService,
              private analyticsService: AnalyticsService,
              @Inject(PLATFORM_ID) protected platformId: any
  ) {
    this.userLoggedStatus = this.userService.curUser$.subscribe(() => {
        this.currentLoginState = this.userService.isLoggedIn();
    });

    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('focus', this.checkLoginOnTabFocus.bind(this));
      window.addEventListener('blur', this.checkLoginOnTabFocus.bind(this));
      a2hsService.checkUserAgent();
      a2hsService.trackStandalone();

      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        a2hsService.promptIntercepted = true;
        a2hsService.deferredPrompt = e;
        a2hsService.promptSaved = true;
      });
      window.addEventListener('appinstalled', () => {
        a2hsService.trackInstalled();
      });

      this.isDesktop = !this.utilities.isTouchDevice();

      const html = document.querySelector('html');
      this.renderer.addClass(html, this.isDesktop ? 'device--desktop' : 'device--mobile');
    }

    if (isPlatformBrowser(this.platformId)) {
      this.router.events.forEach(item => {
        if (item instanceof NavigationEnd) {
          const gtmTag = {
              event: 'page',
              pageName: item.url
          };

          this.gtmService.pushTag(gtmTag);
        }
      });

      this.router.events.pipe(
          filter(event => event instanceof NavigationEnd)
        )
        .subscribe(() => {
          const urlParams = this.route.snapshot.queryParams;
          const filteredParams: {[key: string]: string } = {};

          for (const [key, value] of Object.entries(urlParams)) {
            if (key.toLowerCase().includes('utm_')) {
              filteredParams[key] = value;
            }
          }

          if (Object.keys(filteredParams).length > 0) {
            this.analyticsService.sendUtmTags(filteredParams);
          }
        });
    }
  }

  checkLoginOnTabFocus(e: Event) {
    if (e.type === 'blur') {
      // console.log('you are outside the tab');
    }

    if (this.currentLoginState) {
      if (e.type === 'focus') {
        this.userService.getProfile(true).then(user => {
          if (user.id === '') {
            this.userService.logout().then(() => {
              this.showModalMessage(
                'Session timeout',
                'Your session expired. Please try to login again.'
              );
            });
          }
        }, err => {
          console.error(err.error || err);
          this.userService.logout().then(() => {
            this.showModalMessage(
              'Session timeout',
              'Your session expired. Please try to login again.'
            );
          });
        });
      }
    }
  }

  showModalMessage(title: string, message: string) {
    const modalOptions: NgbModalOptions = {};
    const refModalConf = this.modalService.open(ConfirmationModalComponent, modalOptions);

    refModalConf.componentInstance.onlyMessage = true;
    refModalConf.componentInstance.confirmationModalTitle = title;
    refModalConf.componentInstance.confirmationModalMsg = message;

    refModalConf.result.then(resp => {
      if (resp) {
        console.log(resp);
      }
    })
    .catch(err => {
      console.log(err);
    });
  }
}
