import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {PipesModule} from '../pipes/pipes.module';

import {UserMenuComponent} from './user-menu.component';

import { ImgLazyLoadingModule } from '../image-lazy-loading/image-lazy-loading.module';
import { ShareModule } from '../share/share.module';
import { NotdPartnersModalModule } from '../notd-partners-modal/notd-partners-modal.module';
import { LoginModule } from '../transact-auth/transact-auth.module';

@NgModule({
  declarations: [
    UserMenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PipesModule,
    ImgLazyLoadingModule,
    ShareModule,
    NotdPartnersModalModule,
    LoginModule
  ],
  exports: [
    UserMenuComponent
  ]
})

export class UserMenuModule { }

