import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild} from '@angular/core';
import { ShareComponent } from '../share/share.component';
import { User } from '../types/user';

@Component({
  selector: 'user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.scss']
})

export class UserMenuComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('shareImgContent', {static: false}) public shareImgContent: ElementRef;
  @ViewChild(ShareComponent,  {static: false}) public shareComponent: ShareComponent;

  @Input() user: User | null;
  @Input() triggerMenu = false;
  @Output() logOutEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() mobileMenuEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() openContactModalEvent: EventEmitter<boolean> = new EventEmitter();

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (this.menuVisible && !this.elRef.nativeElement.contains(event.target)) {
      if (this.menuVisible) {
        this.hideMenu();
      }
    }
  }

  public notdScreenElement: ElementRef;
  public menuVisible = false;
  public userShortName: string;

  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    ) { }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.notdScreenElement = this.shareImgContent;
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges', changes);
    if (changes['triggerMenu'] && changes['triggerMenu'].currentValue !== undefined) {
      setTimeout(() => {
        this.menuVisible = changes['triggerMenu'].currentValue;

        const mobileHtmlTag = document.querySelector('.device--mobile');

        if (mobileHtmlTag) {
          this.menuVisible ?
            this.renderer.setStyle(mobileHtmlTag, 'overflow', 'hidden') : this.renderer.removeStyle(mobileHtmlTag, 'overflow');
        }
      }, 0);
    }
  }

  public ngOnInit(): void {
    if (this.user && this.user.id) {
      this.getUserNameShortcut()
    } else {
      this.user = null;
    }
  }

  public toggleMenu(): void {
    console.log('toggleUserMenu', this);
    this.menuVisible = !this.menuVisible;
  }

  public openShareModal(): void {
    this.shareComponent.showShareModal();
  }

  public openContactFormModal(): void {
    this.openContactModalEvent.emit(true);
  }

  public emitLogOutEvent(): void {
    this.logOutEvent.emit(true);
    this.user = null;
    setTimeout(() => {
      this.hideMenu();
    }, 0);
  }

  public hideMenu(): void {
    this.menuVisible = false;
    this.mobileMenuEvent.emit(true);
  }

  private showMenu(): void {
    this.menuVisible = true;
  }

  private getUserNameShortcut(): void {
    const userNameDetails =
      this.user.firstName && this.user.lastName ?  `${this.user.firstName} ${this.user.lastName}` : this.user.displayName || this.user.userName;
    
    const initials = userNameDetails
      .trim()
      .split(/\s+/)
      .map(word => word[0]?.toUpperCase() || '')
      .join('');

    this.userShortName = initials;
  }
}
